import React from 'react';

import { Button } from '../components/Button';

export const Contact = () => {
  return (
    <div className="contact">

    </div>
  );
}
